import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _23b7a169 = () => interopDefault(import('../pages/theme-3/cashier.vue' /* webpackChunkName: "pages/theme-3/cashier" */))
const _0dd06e8f = () => interopDefault(import('../pages/theme-3/login.vue' /* webpackChunkName: "pages/theme-3/login" */))
const _746d890b = () => interopDefault(import('../pages/theme-3/my-bonus/index.vue' /* webpackChunkName: "pages/theme-3/my-bonus/index" */))
const _2951eee6 = () => interopDefault(import('../pages/theme-3/no-support.vue' /* webpackChunkName: "pages/theme-3/no-support" */))
const _646e8cae = () => interopDefault(import('../pages/theme-3/not-found.vue' /* webpackChunkName: "pages/theme-3/not-found" */))
const _c6ab7558 = () => interopDefault(import('../pages/theme-3/payment-methods/index.vue' /* webpackChunkName: "pages/theme-3/payment-methods/index" */))
const _3d4a7a11 = () => interopDefault(import('../pages/theme-3/play-game.vue' /* webpackChunkName: "pages/theme-3/play-game" */))
const _7d5238e8 = () => interopDefault(import('../pages/theme-3/promotion/index.vue' /* webpackChunkName: "pages/theme-3/promotion/index" */))
const _439d6b98 = () => interopDefault(import('../pages/theme-3/refer-friend/index.vue' /* webpackChunkName: "pages/theme-3/refer-friend/index" */))
const _50c2ebed = () => interopDefault(import('../pages/theme-3/register.vue' /* webpackChunkName: "pages/theme-3/register" */))
const _7798ce66 = () => interopDefault(import('../pages/theme-3/reset-password/index.vue' /* webpackChunkName: "pages/theme-3/reset-password/index" */))
const _4f1f86df = () => interopDefault(import('../pages/theme-3/third-party-payment.vue' /* webpackChunkName: "pages/theme-3/third-party-payment" */))
const _4616c3d7 = () => interopDefault(import('../pages/theme-3/history/game.vue' /* webpackChunkName: "pages/theme-3/history/game" */))
const _766a9789 = () => interopDefault(import('../pages/theme-3/history/transaction.vue' /* webpackChunkName: "pages/theme-3/history/transaction" */))
const _5b5e6756 = () => interopDefault(import('../pages/theme-3/payment-methods/add-bank.vue' /* webpackChunkName: "pages/theme-3/payment-methods/add-bank" */))
const _105e505b = () => interopDefault(import('../pages/theme-3/payment-methods/add-crypto-wallet.vue' /* webpackChunkName: "pages/theme-3/payment-methods/add-crypto-wallet" */))
const _3888bd6c = () => interopDefault(import('../pages/theme-3/player/deposits/index.vue' /* webpackChunkName: "pages/theme-3/player/deposits/index" */))
const _0873ef3a = () => interopDefault(import('../pages/theme-3/player/messages/index.vue' /* webpackChunkName: "pages/theme-3/player/messages/index" */))
const _4daad776 = () => interopDefault(import('../pages/theme-3/player/profile.vue' /* webpackChunkName: "pages/theme-3/player/profile" */))
const _955ab906 = () => interopDefault(import('../pages/theme-3/player/withdrawals/index.vue' /* webpackChunkName: "pages/theme-3/player/withdrawals/index" */))
const _4428cb78 = () => interopDefault(import('../pages/theme-3/index.vue' /* webpackChunkName: "pages/theme-3/index" */))
const _182058f4 = () => interopDefault(import('../pages/theme-3/promotion/_id.vue' /* webpackChunkName: "pages/theme-3/promotion/_id" */))
const _b2f2e2e8 = () => interopDefault(import('../pages/theme-3/articles/_title/_slug.vue' /* webpackChunkName: "pages/theme-3/articles/_title/_slug" */))
const _4808856a = () => interopDefault(import('../pages/theme-3/_type/_id.vue' /* webpackChunkName: "pages/theme-3/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cashier",
    component: _23b7a169,
    name: "cashier"
  }, {
    path: "/login",
    component: _0dd06e8f,
    name: "login"
  }, {
    path: "/my-bonus",
    component: _746d890b,
    name: "my-bonus"
  }, {
    path: "/no-support",
    component: _2951eee6,
    name: "no-support"
  }, {
    path: "/not-found",
    component: _646e8cae,
    name: "not-found"
  }, {
    path: "/payment-methods",
    component: _c6ab7558,
    name: "payment-methods"
  }, {
    path: "/play-game",
    component: _3d4a7a11,
    name: "play-game"
  }, {
    path: "/promotion",
    component: _7d5238e8,
    name: "promotion"
  }, {
    path: "/refer-friend",
    component: _439d6b98,
    name: "refer-friend"
  }, {
    path: "/register",
    component: _50c2ebed,
    name: "register"
  }, {
    path: "/reset-password",
    component: _7798ce66,
    name: "reset-password"
  }, {
    path: "/third-party-payment",
    component: _4f1f86df,
    name: "third-party-payment"
  }, {
    path: "/history/game",
    component: _4616c3d7,
    name: "history-game"
  }, {
    path: "/history/transaction",
    component: _766a9789,
    name: "history-transaction"
  }, {
    path: "/payment-methods/add-bank",
    component: _5b5e6756,
    name: "payment-methods-add-bank"
  }, {
    path: "/payment-methods/add-crypto-wallet",
    component: _105e505b,
    name: "payment-methods-add-crypto-wallet"
  }, {
    path: "/player/deposits",
    component: _3888bd6c,
    name: "player-deposits"
  }, {
    path: "/player/messages",
    component: _0873ef3a,
    name: "player-messages"
  }, {
    path: "/player/profile",
    component: _4daad776,
    name: "player-profile"
  }, {
    path: "/player/withdrawals",
    component: _955ab906,
    name: "player-withdrawals"
  }, {
    path: "/",
    component: _4428cb78,
    name: "index"
  }, {
    path: "/promotion/:id",
    component: _182058f4,
    name: "promotion-id"
  }, {
    path: "/articles/:title?/:slug?",
    component: _b2f2e2e8,
    name: "articles-title-slug"
  }, {
    path: "/:type/:id?",
    component: _4808856a,
    name: "type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
